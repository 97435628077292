window.TitleWordColor = (id) => {
    return {
        init() {
            const el = document.getElementById(id);
            if (el) {
                const content = el.innerHTML;
                const bidding = "Bidding";
                const inspecting = "Inspecting";

                const newContent = content.replace(bidding, `<span style="color: #0F8538">${bidding}</span>`).replace(inspecting, `<span style="color: #C45221">${inspecting}</span>`);
                
                el.innerHTML = newContent;
            }
        }
    }
}